import { ReportsService } from './../../services/reports.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SessionService } from 'src/app/services/session.service';
import * as XLSX from "xlsx";
import firebase from 'firebase/app';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  shown = false;

  constructor(
    private _loc: Location,
    private _session: SessionService,
    private _reports: ReportsService
  ) {
    if(!_session.logged) {
      _loc.back();
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.shown = this._session.logged;
    }, 800);
  }

  downloadReport() {
    this._reports.getDataReport1().subscribe( (users: any[]) => {
      const data: any[] = [];

      users.forEach( (u, index) => {
        const brth = u.birthdate;

        data.push([
          index + 1,
          u.createdAt.toDate().toLocaleDateString(),
          u.name,
          u.gender,
          u.assessorType,
          brth.substring(0,2) + "/" + brth.substring(2,4) + "/" + brth.substring(4),
          u.phone,
          u.email,
          u.code,
          u.reference,

          u.inventory.sellProperties,
          u.inventory.rentProperties,
          u.inventory.sellClients,
          u.inventory.rentClients,

          u.connections.connections,
          u.connections.requestSend,
          u.connections.requestRecieve,
          u.connections.intern,
          u.connections.extern,

          u.incomes.sellProperties,
          u.incomes.rentProperties,
          u.incomes.sellClients,
          u.incomes.rentClients,
          u.incomes.mIncome,
        ]);
      });

      this.export(data)
    });
  }

  export(data): void {
    const aoa = [];

    const today = firebase.firestore.Timestamp.now();

    aoa.push([
      "#",
      "Fecha de suscripción",
      "Nombre",
      "Género",
      "Tipo de asesor",
      "Fecha de cumpleaños",
      "Celular",
      "Correo",
      "Mi código:",
      "Código de invitación",

      "# de propiedades venta",
      "# de propiedades renta",
      "# de clientes venta",
      "# de clientes renta",

      "# de conexiones",
      "# de solicitudes enviadas",
      "# de solicitudes recibidas",
      "# de red internas",
      "# de red externas",

      "Propiedades vendidas",
      "Propiedades rentadas",
      "Clientes de venta",
      "Clientes de renta",
      "Ganancia mensual",
    ]);

    data.forEach((record: any, index: number) => {
        aoa.push(record);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(aoa);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Reporte" + today.toDate().toLocaleDateString() + ".xlsx");
  }

}
