import { SessionService } from './../../services/session.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private _session: SessionService,
    private _router: Router
  ) { }

  ngOnInit(): void {
  }

  login() {
    const email: string = (document.getElementById("email") as any).value;
    const password: string = (document.getElementById("password") as any).value;

    if(email.trim() == "" && password.trim() == "") {
      alert("NO PUEDEN QUEDAR DATOS VACIOS");
      return;
    }

    if(email === 'admin@redin.com' && password === 'R3D1N_R3P0R7E5') {
      this._session.logged = true;
      alert('Bienvenido');
      this.gotoReports();
    }
    else 
      alert("CREDENCIALES INCORRECTAS");
  }

  gotoReports() {
    this._router.navigate(['/reportes/'])
  }

}
