import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { forkJoin } from 'rxjs';
import { take, map, catchError, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private _db: AngularFirestore
  ) { }
  
  getDataReport1() {
    let usersList: any[] = [];

    return this._db.collection("users").snapshotChanges()
    .pipe(
      map(users => this.getMetaData(users)),
      mergeMap((users: any[]) => {
        const obsObj = {};
        usersList = users;
        users.forEach( user => {
          obsObj[user.uid] = this.getDataReportByUser(user.uid)
        });
        return forkJoin(obsObj).pipe(take(1))
      }),
      map((dataObj: Object) => {
        const data: any[] = [];

        usersList.forEach( user => {
          data.push({
            ...user,
            ...dataObj[user.uid]
          })
        });
        
        return data;
      }),
      take(1)
    );
  }

  getDataReportByUser(userUid: string) {
    const obsObj = {
      inventory: this.getInventory(userUid),
      incomes: this.getIncomes(userUid),
      connections: this.getConnections(userUid)
    }

    return forkJoin(obsObj).pipe(take(1))
  }

  getInventory(userUid: string) {
    let sellProperties = 0;
    let rentProperties = 0;
    let sellClients = 0;
    let rentClients = 0;

    const propertiesRef = this._db.collection("properties", query => query.where("ownerUid", "==", userUid)).valueChanges();
    const clientsRef = this._db.collection("clients", query => query.where("ownerUid", "==", userUid)).valueChanges();

    return propertiesRef
    .pipe(
      map( (properties: any[]) => {
        sellProperties = properties.filter( p => p.operation == "Venta" ).length;
        rentProperties = properties.filter( p => p.operation == "Renta" ).length;
      }),
      mergeMap(_ => clientsRef),
      map( (clients: any[]) => {
        sellClients = clients.filter( c => c.operation == "Venta" ).length;
        rentClients = clients.filter( c => c.operation == "Renta" ).length;

        return { 
          sellProperties: sellProperties,
          rentProperties: rentProperties,
          sellClients: sellClients,
          rentClients: rentClients
        }
      }),
      take(1)
    );
  }

  getIncomes(userUid: string) {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const id = (year*12) + month;

    let sellProperties = 0;
    let rentProperties = 0;
    let sellClients = 0;
    let rentClients = 0;
    let mIncome = 0;

    const incomesRef = this._db.collection("users").doc(userUid).collection("income").valueChanges();

    return incomesRef
    .pipe(
      map( (incomes: any[]) => {
        incomes.filter( i => { if(i.id >= id - 1) mIncome+=i.amount } );
        sellProperties = incomes.filter( i => i.type == "property" && i.operation == "Venta" ).length;
        rentProperties = incomes.filter( i => i.type == "property" && i.operation == "Renta" ).length;
        sellClients = incomes.filter( i => i.type == "client" && i.operation == "Venta" ).length;
        rentClients = incomes.filter( i => i.type == "client" && i.operation == "Renta" ).length;

        return {
          sellProperties: sellProperties,
          rentProperties: rentProperties,
          sellClients: sellClients,
          rentClients: rentClients,
          mIncome: mIncome,
        }
      }),
      take(1)
    );
  }

  getConnections(userUid: string) {
    let connections = 0;
    let requestSend = 0;
    let requestRecieve = 0;
    let intern = 0;
    let extern = 0;

    const asMatcher = this._db.collection("matches", query => query.where("matcher", "==", userUid)).valueChanges();
    const asMatched = this._db.collection("matches", query => query.where("matched", "==", userUid)).valueChanges();

    let matchesList: any[] = [];
    let matcherList: any[] = [];
    let matchedList: any[] = [];
  
    return asMatcher
    .pipe(
      map( (matcher: any[]) => {
        matcherList = matcher;
      }),
      mergeMap(_ => asMatched),
      map( (matched: any[]) => {
        matchedList = matched;
        matchesList = [...matcherList, ...matchedList];

        connections = matchesList.filter(m => m.matchStatus == 1).length;

        const totalRequest = matchesList.filter(m => m.matchStatus == 2).length;
        requestSend = matchesList.filter(m => m.requerer == userUid).length;
        requestRecieve = totalRequest - requestSend;
        
        const totalNetwork = matchesList.filter(m => m.matchStatus == 3).length;
        intern = matchesList.filter(m => m.selfMatch).length;
        extern = totalNetwork - intern;

        return { 
          connections: connections,
          requestSend: requestSend,
          requestRecieve: requestRecieve,
          intern: intern,
          extern: extern,
        }
      }),
      take(1)
    );
  }

  getMetaData(listData: any) {
    return listData.map((unitData: any) => {
      const data = unitData.payload.doc.data();
      data['uid'] = unitData.payload.doc.id;
      return data
    })
  }
}
