import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { MatIconModule } from '@angular/material/icon';
import { PropertiesComponent } from './modules/properties/properties.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from './../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { ClientsComponent } from './modules/clients/clients.component';
import { LoginComponent } from './modules/login/login.component';
import { ReportsComponent } from './modules/reports/reports.component';

@NgModule({
  declarations: [
    AppComponent,
    PropertiesComponent,
    ClientsComponent,
    LoginComponent,
    ReportsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    // MatIconModule
    
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDz51fHzdCe35eSvhIf4OSKILhfqWxdgjo'
    }),
  ],
  providers: [Meta],
  bootstrap: [AppComponent]
})
export class AppModule { }
