import { ClientService } from './../../services/client.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  uid = '';
  client: any;

  constructor(
    private _client: ClientService,
    private _activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loadParams();
  }

  loadParams() {
    this._activeRoute.params.subscribe(params => {
      this.uid = params['uid'];
      this.getClient();
    });
  }

  getClient() {
    this._client.getClient(this.uid).subscribe( client => {
      this.client = client;
      m(this.client);
    })
  }
}

function m(msg) {
  console.log(msg)
}
