import { ReportsComponent } from './modules/reports/reports.component';
import { LoginComponent } from './modules/login/login.component';
import { ClientsComponent } from './modules/clients/clients.component';
import { PropertiesComponent } from './modules/properties/properties.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  // { path: '', component: PropertiesComponent, pathMatch: 'full' },
  { path: 'propiedades/:uid', component: PropertiesComponent },
  { path: 'clientes/:uid', component: ClientsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'reportes', component: ReportsComponent },

  { path: '**', redirectTo: 'propiedades' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
