// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyAquLvEPZyIJJcWnvaES8g7uiFUg8MvKbM",
    authDomain: "redin-web.firebaseapp.com",
    projectId: "redin-web",
    storageBucket: "redin-web.appspot.com",
    messagingSenderId: "641363049155",
    appId: "1:641363049155:web:7a35abf16b2f851ca68076",
    measurementId: "G-HP5WQGXL4K"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
