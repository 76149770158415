<section class="properties">

    <section class="container-fluid">
        <div class="row mt-2 mt-sm-4 align-items-center">
            <div class="col-12 col-sm-2 text-center text-sm-left">
                <img class="logo" src="./assets/images/logo.png" alt="logo">
            </div>
            <div class="col-12 col-sm-6 text-center">
                <div class="font-italic"><small>"La herramienta de asesor inmobiliario moderno"</small></div>
            </div>
            <div class="d-none d-sm-inline-block col-12 col-sm-4 text-center">
                <a href="https://www.redin-landing.com/">
                    <button class="btn btn-primary btn-sm w-100" style="background-color: #1eafb1; border: none;"><small>Comparte gratis tu catálogo</small></button>
                </a>
            </div>
        </div>

        <div class="row mb-3 pt-3 pt-sm-4 sticky-top" style="background-color: white">

            <div class="d-inline-block d-sm-none col-12 mb-3 text-center">
                <a href="https://www.redin-landing.com/">
                    <button class="btn btn-primary btn-sm w-100" style="background-color: #1eafb1; border: none;"><small>Comparte gratis tu catálogo</small></button>
                </a>
            </div>

            <div class="col-12 col-md-8">

                <span class="nav-l" [hidden]="index == -1" (click)="back()">
                    <div class="r-icon"><span class="material-icons-outlined">chevron_left</span></div>
                </span>
                <span class="nav-r" [hidden]="index == -1" (click)="go()">
                    <div class="r-icon"><span class="material-icons-outlined">chevron_right</span></div>
                </span>

                <div class="img-carusel py-5" [style.background-image]=" index == -1 ? '' : 'url(' + property?.photos[index] + ')' ">
                    <div  *ngIf="index != -1" class="my-3 py-3 my-sm-5 py-md-5">
                        <div class="py-0 my-0 py-md-3 my-lg-3" style="color: transparent;">.</div>
                    </div>

                    <div *ngIf="index == -1" class="d-flex anchor-full justify-content-center py-5">
                        <div class="d-flex align-items-center ">
                          <img src="../../../assets/images/logo_gray.png" alt="watermark" width="200">
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-12 col-md-4">
                <section class="container-fluid px-0 mx-0 mt-3 mt-md-0">
                    <section class="row">
                        <div class="mb-1 pb-0 pb-md-4 col-12 col-sm-6 col-md-12">
                            <h4><b>{{property?.type}} en <span class="text-muted">{{property?.operation}}</span></b></h4>
                            <div class="text-muted" style="margin-top: -15px;"><small>{{property?.suburb}}</small></div>
                        </div>

                        <div class="mb-2 pb-0 pb-md-4 col-6 col-sm-6 col-md-12">
                            <h4 class="primary-color"><b>{{property?.price | currency:'USD':'symbol':'1.0-0' }}</b></h4>
                            <div class="text-muted" style="margin-top: -15px;"><b><small>MXN</small></b></div>
                        </div>

                        <div class="mb-2 pb-0 pb-md-4 col-6 col-sm-6 col-md-12 d-none d-md-inline-block">
                            <div class="r-icon"><span class="material-icons-outlined">sell</span></div> 
                            <small> {{ property?.salePhrase == undefined ? 'No aplica' : '"' + property?.salePhrase + '"'}}</small>
                        </div>

                        <div class="mb-2 pb-0 pb-md-4 col-6 col-sm-6 col-md-12 d-none d-md-inline-block">
                            <div class="r-icon"><span class="material-icons-outlined">room</span></div> 
                            <small> En zona <b>{{property?.zone}}</b></small>
                        </div>

                        <div class="mb-2 mb-md-4 pb-0 pb-md-4 col-6 col-sm-6 col-md-12">
                            <div class="r-icon"><span class="material-icons-outlined">tag</span></div> 
                            <small><b>{{property?.code == undefined ? 'N/A' : property?.code}}</b></small>
                        </div>

                    </section>
                </section>
            </div>
        </div>

        <div class="row">
            <div class="mb-4 col-sm-6 col-md-4 d-inline-block d-md-none"> 
                <div class="r-icon"><span class="material-icons-outlined">sell</span></div> 
                <small> {{ property?.salePhrase == undefined ? 'No aplica' : '"' + property?.salePhrase + '"'}}</small>
            </div>
            <div class="mb-4 col-sm-6 col-md-4 d-inline-block d-md-none"> 
                <div class="r-icon"><span class="material-icons-outlined">room</span></div> 
                <small> En zona <b>{{property?.zone}}</b></small>
            </div>
            
            <div class="mb-4 col-sm-6 col-md-4"> 
                <div class="r-icon"><span class="material-icons-outlined">chair</span></div> 
                <small> {{property?.type}} <b>{{property?.furnitureStatus}}</b></small>
            </div>
            <div class="mb-4 col-sm-6 col-md-4"> 
                <div class="r-icon"><span class="material-icons-outlined">maps_home_work</span></div> 
                <small> Fraccionamiento <b>{{property?.neighborhoodStatus}}</b></small>
            </div>
            <div class="mb-4 col-sm-6 col-md-4"> 
                <div class="r-icon"><span class="material-icons-outlined">today</span></div> 
                <small> {{ property?.buildingYear == undefined ? 'Fecha no registrada' : 'Construida en ' }}<b>{{property?.buildingYear}}</b></small>
            </div>
            <div class="mb-4 col-sm-6 col-md-4"> 
                <div class="r-icon"><img class="d-inline-block vam" style="margin: -3px 0px 0px 2px;" width="20" src="../../../assets/images/meters_icon.png" alt="meters"></div> 
                <small> <b>{{property?.terrainMeters}} m<sup>2</sup></b> de terreno</small>
            </div>
            <div class="mb-4 col-sm-6 col-md-4"> 
                <div class="r-icon"><span class="material-icons-outlined">home</span></div> 
                <small> <b>{{property?.buildingMeters}} m<sup>2</sup></b> de construcción</small>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12">
                <h5 class="mb-3"><b>Características</b></h5>
            </div>
            
            <!-- <div class="col-sm-6 col-md-4 mb-4">
                <div class="r-icon"><span class="material-icons-outlined">bed</span></div> 
                <small> {{property.bedrooms}} Dormitorio(s)</small>
            </div> -->
            <div class="col-sm-6 col-md-4 mb-4">
                <div class="r-icon"><span class="material-icons-outlined">living</span></div> 
                <small> {{property?.rooms}} Habitacion(es)</small>
            </div>
            <div class="col-sm-6 col-md-4 mb-4">
                <div class="r-icon"><span class="material-icons-outlined">layers</span></div> 
                <small> {{property?.levels}} Piso(s)</small>
            </div>
            <div class="col-sm-6 col-md-4 mb-4">
                <div class="r-icon"><span class="material-icons-outlined">directions_car</span></div> 
                <small> {{property?.parking}} Cochera(s)</small>
            </div>
            <div class="col-sm-6 col-md-4 mb-4">
                <div class="r-icon"><span class="material-icons-outlined">bathtub</span></div> 
                <small> {{property?.bathrooms}} Baño(s)</small>
            </div>
            <div class="col-sm-6 col-md-4 mb-4">
                <div class="r-icon"><img class="d-inline-block vam" style="margin: -3px 0px 0px 1px;" width="20" src="../../../assets/images/toilet_icon.png" alt="meters"></div> 
                <small> {{property?.halfBaths}} Medio(s) baño(s)</small>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12">
                <h5 class="mb-3"><b>Detalles</b></h5>
            </div>

            <div *ngFor="let f of property?.features" [hidden]="!f.check" class="col-sm-6 col-md-4 mb-2">
                <span style="vertical-align: middle;" class="material-icons-outlined primary-color">check_circle</span>
                <span style="vertical-align: middle;" class="ml-3">{{f.name}}</span>
            </div>

        </div>

        <div class="row mt-4">
            <div class="col-12">
                <h5 class="mb-3"><b>Ubicación aproximada</b></h5>
                <!-- sumar restar al 5to digito-->
                <agm-map [latitude]="property?.location?.latitude" [longitude]="property?.location?.longitude" [zoom]="zoom">
                    <!-- <agm-marker [latitude]="property.location.latitude" [longitude]="property.location.longitude"></agm-marker> -->
                    <agm-circle fillColor="#1eafb1" fillOpacity="0.3" strokeWeight="2" strokeColor="#1eafb1" [radius]="600" 
                    [latitude]="property?.location?.latitude" [longitude]="property?.location?.longitude"></agm-circle>
                  </agm-map>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 text-center mb-2">
                <div class="font-italic"><small>Comparte tus propiedades fácil, rápido y GRATIS con Redin.</small></div>
            </div>
            <div class="col-12 text-center">
                <a href="https://www.redin-landing.com/">
                    <button class="btn btn-primary" style="background-color: #1eafb1; border: none;"><small>¡Comienza aquí!</small></button>
                </a>
            </div>
        </div>

        <div class="row my-2">
            <div class="col-12 text-center">
                <div class="font-italic"><small><b>Redin</b> Red Inmobiliaria 2021 | Todos los derechos reservados</small></div>
            </div>
        </div>
    </section>
   
</section>