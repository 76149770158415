import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, map, catchError, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(
    private _db: AngularFirestore
  ) { }

  getClient(uid) {
    const ref = this._db.collection('clients').doc(uid);
    return ref.valueChanges().pipe(take(1));
  }
}
