<section class="properties">

    <section class="container-fluid">
        <div class="row mt-2 mt-sm-4 align-items-center">
            <div class="col-12 col-sm-2 text-center text-sm-left">
                <img class="logo" src="./assets/images/logo.png" alt="logo">
            </div>
            <div class="col-12 col-sm-6 text-center">
                <div class="font-italic"><small>"La herramienta de asesor inmobiliario moderno"
                </small></div>
            </div>
            <div class="d-none d-sm-inline-block col-12 col-sm-4 text-center">
                <a href="https://www.redin-landing.com/">
                    <button class="btn btn-primary btn-sm w-100" style="background-color: #1eafb1; border: none;"><small>Comparte gratis tu catálogo</small></button>
                </a>
            </div>
        </div>

        <div class="row sticky-top py-3" style="background-color: white;">
            <div class="d-inline-block d-sm-none col-12 mb-3 text-center">
                <a href="https://www.redin-landing.com/">
                    <button class="btn btn-primary btn-sm w-100" style="background-color: #1eafb1; border: none;"><small>Comparte gratis tu catálogo</small></button>
                </a>
            </div>

            <div class="col-12">
                <div class="client-fill">
                    <span class="material-icons-two-tone">
                        person_search
                    </span>
                </div>
            </div>
        </div>

        <div class="row mb-3 pt-3 pt-sm-4" style="background-color: white">

            <div class="mb-1 col-12">
                <div class="text-muted" style="margin-top: -15px;"><small>Cliente busca</small></div>
                <h4><b>{{client.type}} en <span class="text-muted">{{client?.operation}}</span></b></h4>
            </div>

            <div class="col-12 mt-3">
                <div class="text-muted" style="margin-top: -15px;"><small>Presupuesto</small></div>
            </div>

            <div class="mb-2 col-6">
                <h5 class="primary-color"><b>{{client?.priceMin | currency:'USD':'symbol':'1.0-0' }}</b></h5>
                <div class="text-muted" style="margin-top: -15px;"><b><small>MIN</small></b></div>
            </div>

            <div class="mb-2 col-6">
                <h5 class="primary-color"><b>{{client?.priceMax | currency:'USD':'symbol':'1.0-0' }}</b></h5>
                <div class="text-muted" style="margin-top: -15px;"><b><small>MAX</small></b></div>
            </div>

            <div class="mb-2 mt-3 col-12">
                <div class="r-icon"><span class="material-icons-outlined">tag</span></div> 
                <small><b>{{client?.code == undefined ? 'N/A' : client?.code}}</b></small>
            </div>

            <div class="mb-2 col-12">
                <div class="r-icon"><span class="material-icons-outlined">chair</span></div> 
                <small> Amueblado: <b>{{ client?.furnitureStatus }}</b></small>
            </div>

            <div class="mb-2 col-12">
                <div class="r-icon"><span class="material-icons-outlined">room</span></div> 
                <small> En zona <b>{{client?.zone}}</b></small>
            </div>
        </div>


        <div class="row mt-4">
            <div class="col-12">
                <h5 class="mb-3"><b>Características</b></h5>
            </div>
            
            <div class="col-sm-6 col-md-4 mb-4">
                <div class="r-icon"><span class="material-icons-outlined">bed</span></div> 
                <small> {{client.bedrooms == undefined ? 'N/A' : client.bedrooms}} Dormitorio(s)</small>
            </div>
            <div class="col-sm-6 col-md-4 mb-4">
                <div class="r-icon"><span class="material-icons-outlined">living</span></div> 
                <small> {{client.rooms == undefined ? 'N/A' : client.rooms}} Habitacion(es)</small>
            </div>
            <div class="col-sm-6 col-md-4 mb-4">
                <div class="r-icon"><span class="material-icons-outlined">directions_car</span></div> 
                <small> {{client.parking == undefined ? 'N/A' : client.parking}} Cochera(s)</small>
            </div>
            <div class="col-sm-6 col-md-4 mb-4">
                <div class="r-icon"><span class="material-icons-outlined">bathtub</span></div> 
                <small> {{client.bathrooms == undefined ? 'N/A' : client.bathrooms}} Baño(s)</small>
            </div>
            <div class="col-sm-6 col-md-4 mb-4">
                <div class="r-icon"><img class="d-inline-block vam" style="margin: -3px 0px 0px 1px;" width="20" src="../../../assets/images/toilet_icon.png" alt="meters"></div> 
                <small> {{client.halfBaths == undefined ? 'N/A' : client.halfBaths}} Medio(s) baño(s)</small>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-12 text-center mb-2">
                <div class="font-italic"><small>Comparte tus propiedades fácil, rápido y GRATIS con Redin.</small></div>
            </div>
            <div class="col-12 text-center">
                <a href="https://www.redin-landing.com/">
                    <button class="btn btn-primary" style="background-color: #1eafb1; border: none;"><small>¡Comienza aquí!</small></button>
                </a>
            </div>
        </div>

        <div class="row my-2">
            <div class="col-12 text-center">
                <div class="font-italic"><small><b>Redin</b> Red Inmobiliaria 2021 | Todos los derechos reservados</small></div>
            </div>
        </div>
    </section>
   
</section>