import { PropertyService } from './../../services/property.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css']
})
export class PropertiesComponent implements OnInit {

  uid = '';
  property: any;
  zoom = 14;
  index = -1;

  constructor(
    private _property: PropertyService,
    private _activeRoute: ActivatedRoute,
    public _title: Title
  ) { 
  }

  ngOnInit() {
    this.loadParams();
  }

  loadParams() {
    this._activeRoute.params.subscribe(params => {
      this.uid = params['uid'];
      this.getProperty();
    });
  }

  getProperty() {
    this._property.getProperty(this.uid).subscribe( property => {
      this.property = property;

      if(this.property.photos.length != 0)
        this.index = 0;

      m(this.index)
      m(this.property);
    })
  }

  go() {
    if(this.index + 1 > this.property.photos.length - 1)
      this.index = 0;
    else 
      this.index++;
  }
  
  back() {
    m('back')

    if(this.index - 1 < 0)
      this.index = this.property.photos.length - 1;
    else
      this.index--;

    m(this.index)
  }
}

function m(msg) {
  console.log(msg)
}
